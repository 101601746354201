<template>
  <div class="error_body">
    <div class="error_content">
      <img src="../assets/error/1001.png" width="500px" height="300px" />
      <p>登录已失效，请重新登录</p>
      <el-button type="primary" size="mini" style="width:100px;margin-top:20px;background:#409EFF;border:1px solid #409EFF" round @click="toLogin">返回首页</el-button>
      
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods:{
    toLogin(){
      this.$router.push('/login');
    }
  }
};
</script>
<style lang='scss'>
.error_body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  .error_content {
    margin: auto;
    text-align: center;
    p{
      font-size: 14px;
      color: #909399;
    }
  }
}
</style>